body {
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  overflow: hidden;
}

::-webkit-scrollbar {
  background-color: #2b2b2b;
  border-left: 1px solid #3e3e3e;
  width: .65rem;
}

::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border-radius: 10px;
}

