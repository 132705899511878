.react-datepicker__input-container > input {
    width: 100%;
}

.react-datepicker__input-container > input.error {
    color: var(--chakra-colors-red-500);
    border-width: 1px;
    border-color: var(--chakra-colors-red-500);
    border-style: solid;
}
